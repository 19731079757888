//TabsSection.js
import React, { useState } from 'react';
import './TabsSection.css';
import BatDau from './BatDau';
import GioiThieu from './GioiThieu';
import DichVu from './DichVu';
import CamNang from './CamNang';
import LienHe from './LienHe';
import Dropdown from 'react-bootstrap/Dropdown';

function TabsSection() {
  const [activeTab, setActiveTab] = useState('home');
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [dropdownTab, setDropdownTab] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const openTab = (tab) => {
    setActiveTab(tab);
  };


  const handleSelectDropdownItemGt = (machine) => {
    setSelectedMachine(machine);
    setIsDropdownOpen(false);
    setActiveTab('gioiThieu');
  };

  const handleSelectDropdownItemCn = (machine) => {
    setSelectedMachine(machine);
    setIsDropdownOpen(false);
    setActiveTab('camNang');
  };




  return (
    <div>
      <div className="tabs">
       {/* Page view */}
        {/* Trung Nhân - Cơ sở Vật lý Trị liệu */}
        <button id="tabsHome" className={activeTab === 'home' ? 'tablinks active' : 'tablinks'} onClick={() => openTab('home')}>
          Trung Nhân - Cơ sở Vật lý Trị liệu
        </button>


        {/* Giới Thiệu */}
        <Dropdown>
          {/* <Dropdown.Toggle className={activeTab === 'gioiThieu' ? 'tablinks active' : 'tablinks'}>
            Giới Thiệu
          </Dropdown.Toggle> */}

          <Dropdown.Toggle className={`tablinks-dropdown ${activeTab === 'gioiThieu' ? 'active' : ''}`}>
            Giới Thiệu
          </Dropdown.Toggle>


          <Dropdown.Menu className="dropdown-content">
            <button onClick={() => handleSelectDropdownItemGt('Giới thiệu về phòng tập')}>Giới thiệu về phòng tập</button>
            <button onClick={() => handleSelectDropdownItemGt('Máy điều trị điện xung')}>Máy điều trị điện xung</button>
            <button onClick={() => handleSelectDropdownItemGt('Máy kéo cột sống')}>Máy kéo cột sống</button>
            <button onClick={() => handleSelectDropdownItemGt('Máy nén ép trị liệu Gapo')}>Máy nén ép trị liệu Gapo</button>
            <button onClick={() => handleSelectDropdownItemGt('Máy siêu âm đa tầng')}>Máy siêu âm đa tầng</button>
          </Dropdown.Menu>
        </Dropdown>



        {/* Dịch Vụ Điều Trị */}
        <button className={activeTab === 'dichVu' ? 'tablinks active' : 'tablinks'} onClick={() => openTab('dichVu')}>
          Dịch Vụ Điều Trị
        </button>


        {/* Cẩm nang sống khỏe */}
        <Dropdown className="dropdown">
          {/* <Dropdown.Toggle className={activeTab === 'camNang' ? 'tablinks active' : 'tablinks'}> */}
          <Dropdown.Toggle className={`tablinks-dropdown ${activeTab === 'camNang' ? 'active' : ''}`}>
            Cẩm nang sống khỏe
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-content">
            <button onClick={() => handleSelectDropdownItemCn('Đau lưng')}>Nguyên nhân mỏi lưng và cách trị</button>
            <button onClick={() => handleSelectDropdownItemCn('Kéo Giãn cột sống')}>Kéo giãn cột sống là gì? Tác dụng và ưu điểm</button>
            <button onClick={() => handleSelectDropdownItemCn('Thoát vị đĩa đệm')}>Thoát vị đĩa đệm là gì?</button>
            <button onClick={() => handleSelectDropdownItemCn('Thần kinh tọa')}>Bệnh thần kinh tọa là gì?</button>
          </Dropdown.Menu>
        </Dropdown>


        {/* Liên hệ */}
        <button className={activeTab === 'lienHe' ? 'tablinks active' : 'tablinks'} onClick={() => openTab('lienHe')}>
          Liên hệ
        </button>
      </div>



    {/* Load page */}
      <div className="wrapper_tabcontent">
        <div className={activeTab === 'home' ? 'tabcontent active' : 'tabcontent'}>
          <BatDau />
        </div>

        <div className={activeTab === 'gioiThieu' || dropdownTab === 'dropdown' ? 'tabcontent active' : 'tabcontent'}>
          <GioiThieu selectedMachine={selectedMachine} />
        </div>

        <div className={activeTab === 'dichVu' ? 'tabcontent active' : 'tabcontent'}>
          <DichVu/>
        </div>

        <div className={activeTab === 'camNang' || dropdownTab === 'dropdown' ? 'tabcontent active' : 'tabcontent'}>
          <CamNang selectedMachine={selectedMachine} />
        </div>

        <div className={activeTab === 'lienHe' ? 'tabcontent active' : 'tabcontent'}>
          <LienHe/>
        </div>
      </div>
    </div>
  );
}

export default TabsSection;
