// App.js
import React from 'react';
import TabsSection from './components/TabsSection';
import Footer from './components/Footer';
import './App.css';
// import IconChat from './components/IconChat';

function App() {
  // const handleChatClick = () => {
  //   // Xử lý sự kiện khi biểu tượng chat được nhấp vào
  //   console.log('Chat icon clicked');
  // };
  return (
    <div className="App">
      <TabsSection />
      <script type="text/javascript" src="https://cdn.fchat.vn/assets/embed/webchat.js?id=65ffcecdcbef121eb87a1d70" async="async"></script>
      {/* <IconChat messageOwner="John Doe" unreadCount={3} onClick={handleChatClick} /> */}
    
      <Footer />
    </div>
  );
}

export default App;
