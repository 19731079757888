// DichVu.js
import React from 'react';
import './DichVu.css';

import TapVanDong from '../images/tapvandong.jpg';
import KeoCotSong from '../images/keocotsong.jpg';
import NenApLucHoi from '../images/nenapluchoi.jpg';
import SieuAm from '../images/sieuam.jpg';
import SongXungKich from '../images/songxungkich1.jpg';
import DienXungNen from '../images/dienxungnen.jpg';

const DichVu = () => {
  return (
    <div id="body">     
      <table id="table1">
        <tr>
          <td id="dichvuText">Dịch vụ</td>
          <td>
            <p>
              Tập vận động là một phần quan trọng của chương trình phục hồi và 
              chăm sóc sức khỏe. Chúng tôi cung cấp các bài tập và phương pháp 
              tập luyện chuyên biệt, nhằm cải thiện sự linh hoạt, tăng cường cường 
              độ và phục hồi cơ bắp và khớp xương.
            </p>
          </td>
        </tr>
      </table>

      <table id="table2">
        <tr>
          <td>
            <img src={TapVanDong} />
            <div id="topic">Tập vận động</div>
            <p>
              Tập vận động là một phần quan trọng của chương trình phục hồi và chăm sóc sức khỏe. 
              Chúng tôi cung cấp các bài tập và phương pháp tập luyện chuyên biệt, nhằm cải thiện 
              sự linh hoạt, tăng cường cường độ và phục hồi cơ bắp và khớp xương.
            </p>
          </td>
          <td>
            <img src={KeoCotSong} />
            <div id="topic">Phương pháp kéo cột sống</div>
            <p>Phương pháp kéo cột sống là một giải pháp hiệu quả trong điều trị các vấn đề liên quan đến cột sống như đau lưng, thoái hóa đốt sống và giãn cách các đốt sống. Qua việc áp dụng kỹ thuật kéo giãn và kiểm soát áp lực, chúng tôi giúp cải thiện sự linh hoạt và giảm đau cho bệnh nhân.</p>
          </td>
          <td>
            <img src={NenApLucHoi} />
            <div id="topic">Nén áp lực hơi</div>
            <p>Nén áp lực hơi là một phương pháp điều trị tiên tiến, sử dụng công nghệ nén khí để giảm áp lực và tạo cảm giác thư giãn cho cơ bắp và mạch máu. Quá trình này không chỉ giảm căng thẳng mà còn kích thích tuần hoàn máu, cải thiện sự lưu thông và giảm viêm.</p>
          </td>
        </tr>

        <tr>
          <td>
            <img src={SieuAm} />
            <div id="topic">Siêu âm điều trị</div>
            <p>Siêu âm điều trị là một phương pháp tiên tiến trong việc điều trị cơ bắp và khớp xương, giúp giảm đau và tăng cường sự phục hồi. Sử dụng sóng siêu âm để tạo ra các hiệu ứng cơ học và nhiệt độ, giúp cải thiện sự linh hoạt và giảm đau hiệu quả.</p>
          </td>
          <td>
            <img src={SongXungKich} />
            <div id="topic">Sóng xung kích</div>
            <p>Sóng xung kích là một phương pháp hiệu quả để điều trị viêm, đau và tăng cường sự phục hồi của cơ bắp và mô liên kết. Các sóng xung kích kích thích quá trình tự nhiên của cơ thể, giúp giảm viêm, đau và tăng cường sự phục hồi sau chấn thương.</p>
          </td>
          <td>
            <img src={DienXungNen} />
            <div id="topic">Điện xung nén - hút</div>
            <p>Điện xung nén - hút là một phương pháp hiệu quả để giảm đau và cải thiện sự linh hoạt của cơ bắp và mạch máu. Kết hợp kích thích điện kèm theo áp lực nén và hút, chúng tôi giúp cải thiện tuần hoàn máu và giảm căng thẳng cơ bắp một cách hiệu quả.</p>
          </td>
        </tr>
      </table>

      <div className="divBlog">
        <h3><strong>ĐẶC BIỆT</strong></h3>
        <p><strong><li>Gói 3 lần giảm 10%</li></strong></p>
        <p><strong><li>Gói 5 lần tặng 1 lần</li></strong></p>
        <p><strong><li>Gói 10 lần tặng 3 lần</li></strong></p>
      </div>
    </div>
  );  
};

export default DichVu;
