// Body.js
import React from 'react';
import bg from '../images/BANGHIEU01.jpg';
import './BatDau.css';

const BatDau = () => {
  
  return (
    <div>
      <img id="imageHeader" src={bg} alt="Background" />
      <button id="BatDauBtn" >
        Hãy bắt đầu
      </button>
    </div>
  );
};

export default BatDau;
